<template>
  <div class="charter">
    <div style="width: 20%;">
      <plateNavigation />
    </div>
    <div class="charter-content">
      <carousel />
      <ranking :msg="msg" @collection="collection" @subscribe="subscribe"></ranking>
      <div class="new">
        <div class="newImg">最新图片</div>
        <div class="new-content">
          <div style="width:40%;">
            <newCarousel />
          </div>
          <div class="new-lists" style="width:55%">
            <tab />
          </div>
        </div>
      </div>
      <div class="posting">发帖</div>
      <div class="tag">
        <tag />
      </div>
      <div class="area-list">
        <all @onChange="onChange"></all>
        <div class="list-item">
          <imgPattren ref="img" v-if="isPattern" @getTotal="getTotal" :pageNum="pageNum"></imgPattren>
          <fontPattren ref="font" v-else></fontPattren>
          <pagination @onChangePagination="pagination" :totalNum="totalNum"></pagination>
        </div>
      </div>
      <div class="posting">发帖</div>
      <VueUeditor @updateDeatil="updateDeatil" />
    </div>
  </div>
</template>
<script>
import carousel from '../componets/carousel';
import newCarousel from '../componets/newCarousel';
import ranking from '../componets/ranking';
import imgPattren from '../componets/imgPattren';
import fontPattren from '../componets/fontPattren';
import all from '../componets/all';
import pagination from '../componets/pagination';
import tab from '../componets/tab';
import tag from '../componets/tag';
import VueUeditor from '../componets/VueUeditor';
export default {
  components: { carousel, newCarousel, imgPattren, fontPattren, all, pagination, ranking, tab, tag, VueUeditor },
  data() {
    return {
      isPattern: true,
      msg: {
        id: '',
        cate_name: '二手买卖',
      },
      totalNum: 0,
      pageNum: 1,
    };
  },
  mounted() {},
  methods: {
    collection(e) {},
    subscribe(e) {},

    pagination(e) {
      this.pageNum = e;
    },

    getTotal(e) {
      this.totalNum = e;
    },
    onChange(e) {
      if (e.target.checked) {
        this.isPattern = true;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.img.getList('', true);
          }, 10);
        });
      } else {
        this.isPattern = false;
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.font.getList('', true);
          }, 10);
        });
      }
    },
    updateDeatil() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isPattern) {
            this.$refs.img.getList('', true);
          } else {
            this.$refs.font.getList('', true);
          }
        }, 10);
      });
    },
    btnAll() {
      this.isPagination = true;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isPattern) {
            this.$refs.img.getList('', true);
          } else {
            this.$refs.font.getList('', true);
          }
        }, 10);
      });
    },
    btnHot(list) {
     
      this.isPagination = false;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isPattern) {
            this.$refs.img.getList(list, false);
          } else {
            this.$refs.font.getList(list, false);
          }
        }, 10);
      });
    },
    btnHotPost(list) {
      this.isPagination = false;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isPattern) {
            this.$refs.img.getList(list, false);
          } else {
            this.$refs.font.getList(list, false);
          }
        }, 10);
      });
    },
    btnElite(list) {
      this.isPagination = false;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isPattern) {
            this.$refs.img.getList(list, false);
          } else {
            this.$refs.font.getList(list, false);
          }
        }, 10);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.charter {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 30px 0;
  .charter-content {
    width: 75%;
    .new {
      .newImg {
        font-size: 18px;
        color: #ccc;
        font-weight: 600;
        margin: 30px 0;
      }
      .new-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }
    .posting {
      width: 100px;
      height: 40px;
      border-radius: 10px;
      background: #ffcc00;
      color: #fff;
      font-size: 16px;
      text-align: center;
      line-height: 40px;
      margin: 30px 0;
      cursor: pointer;
    }
    .area-list {
      .list-item {
        .ant-pagination {
          display: flex;
          justify-content: flex-end;
          color: #fff;
        }
      }
    }
  }
}
</style>
